<template>
    <v-app id="login">
        <v-container d-flex id="login_admin">
            <v-row>
                <v-col cols="12" class="align-center ">
                    <v-card class="elevation-3 pa-3">
                        <v-card-title class="text-center justify-center">
                            <div>
                                <v-img src="@/assets/image/LogoGris.png" contain width="350px" height="auto"></v-img>
                                <h3 class="my-4 primary--text">{{ $store.state.appName }}</h3>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" v-model="formValid" @submit.prevent="">
                                <v-text-field
                                    prepend-icon="person"
                                    type="text"
                                    :label="usuarioForm.usuario.label"
                                    :placeholder="usuarioForm.usuario.placeholder"
                                    required
                                    v-model="usuarioModel.usuario"
                                    :rules="usuarioForm.usuario.rules"
                                    @keyup.enter="login"
                                />
                                <v-text-field
                                    prepend-icon="key"
                                    :type="hidePassword ? 'password' : 'text'"
                                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                                    :label="usuarioForm.password.label"
                                    :placeholder="usuarioForm.password.placeholder"
                                    v-model="usuarioModel.password"
                                    :rules="usuarioForm.usuario.rules"
                                    @click:append="hidePassword = !hidePassword"/>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-center py-3">
                            <v-btn block color="primary" @click="login" :loading="loading">Iniciar Sesión</v-btn>
                        </v-card-actions>
                        <v-fab-transition>
                            <v-btn
                                color="green darken-4"
                                outlined
                                fixed
                                bottom
                                right
                                @click="goLoginCertificados">
                                Certificados
                                <v-icon>login</v-icon>
                            </v-btn>
                        </v-fab-transition>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>

</template>

<script>
import Ls from "@/services/ls";

export default {
    name: 'LoginAdmin',
    data() {
        return {
            loading: false,
            hidePassword: true,
            formValid: true,
            usuarioModel: {
                usuario: null,
                password: null
            },
            usuarioForm: {
                usuario: {
                    label: 'Usuario',
                    placeholder: 'Usuario',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Contraseña',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
            },
        }
    },
    methods: {
        goLoginCertificados() {
            this.$router.push({name: "LoginCertificados"});
        },
        login() {
            if (this.$refs.form.validate()) {

                this.loading = true;

                let params = {
                    usuario: this.usuarioModel.usuario,
                    password: this.usuarioModel.password,
                };


                window.axios.post('/login', params).then(async response => {
                    if (response.data.status == "active") {
                        let data = response.data.data;
                        Ls.set('token', response.data.token);
                        Ls.set('tipo_persona', data.cat_tipo_persona_id);
                        Ls.set('nombre', data.nombre);
                        Ls.set('apellido', data.apellido);

                        await this.$router.push({name: 'Inicio'});

                    } else if (response.data.status == "inactive") {

                        this.$toast.error(response.data.message);
                    } else if (response.data.status == "invalid") {

                        this.$toast.error(response.data.message);
                    } else if (response.data.status == "nodata") {

                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo conectar");
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete la información requerida.");
            }
        },
    },
    created() {
        if (Ls.get("token")) {
            this.$router.push({name: 'Inicio'});
        }
    }
}

</script>

<style src="./Login.scss" lang="scss"/>
